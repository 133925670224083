import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";

// Image
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordDTO, UsersService } from "api/http";

const BasicResetPassword = () => {
  document.title = "Reset Password ";

  const navigate = useNavigate();

  const mutationSendEmail = useMutation({
    mutationFn: (params: ForgotPasswordDTO) => {
      return UsersService.forgotPasswordV1UsersForgotPasswordPost(params);
    },
    onSuccess: (_, variables) => {
      console.log(variables);
      navigate("/create-password", { state: { email: variables.email } });
    },
  });

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email")
        .required("Please Enter Your email"),
    }),
    onSubmit: (values: ForgotPasswordDTO) => {
      mutationSendEmail.mutate(values);
    },
  });

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      "flex",
      "items-center",
      "justify-center",
      "min-h-screen",
      "py-16",
      "lg:py-10",
      "bg-slate-50",
      "dark:bg-zink-800",
      "dark:text-zink-100",
      "font-public"
    );

    return () => {
      bodyElement.classList.remove(
        "flex",
        "items-center",
        "justify-center",
        "min-h-screen",
        "py-16",
        "lg:py-10",
        "bg-slate-50",
        "dark:bg-zink-800",
        "dark:text-zink-100",
        "font-public"
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative">
        <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 dark:shadow-zink-500/20 relative">
          <div className="!px-10 !py-12 card-body">
            <Link to="/">
              <div className="block h-6 mx-auto font-semibold text-3xl w-fit">
                eCom Copilot
              </div>
            </Link>

            <div className="mt-8 text-center">
              <h4 className="mb-2 text-custom-500 dark:text-custom-500">
                Forgot Password?
              </h4>
              <p className="mb-8 text-slate-500 dark:text-zink-200">
                Reset your eCom Copilot password
              </p>
            </div>

            <div className="px-4 py-3 mb-6 text-sm text-yellow-500 border border-transparent rounded-md bg-yellow-50 dark:bg-yellow-400/20">
              Provide your email address, and instructions will be sent to you
            </div>

            <form
              id="resetPasswordForm"
              onSubmit={(event: any) => {
                event.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div>
                <label
                  htmlFor="emailInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  required
                  placeholder="Enter email"
                  id="emailInput"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div id="email-error" className="mt-1 text-sm text-red-500">
                    {validation.errors.email}
                  </div>
                ) : null}

                {mutationSendEmail.isError ? (
                  <div id="email-error" className="mt-1 text-sm text-red-500 ">
                    Email not found
                  </div>
                ) : null}
              </div>
              <div className="mt-8">
                <button
                  type="submit"
                  className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Send Reset Link
                </button>
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link
                    to="/auth-login-basic"
                    className="underline fw-medium text-custom-500"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicResetPassword;
