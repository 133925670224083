import { useQuery } from "@tanstack/react-query";
import {
  getTraficSourceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTraficSourcePostOptions
} from "api/client/@tanstack/react-query.gen";
import { DistributedColumns } from "pages/ApexCharts/ColumnCharts/DistributedColumns";
import React, { useMemo } from "react";

type trafficSourceProps = {
  system_shop_id?: string;
  start_date?: string;
  end_date?: string;
};

const TrafficSource = ({
  system_shop_id,
  start_date,
  end_date,
}: trafficSourceProps) => {
  const { data: trafficSource } = useQuery({
    ...getTraficSourceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTraficSourcePostOptions(
      {
        path: { system_shop_id: system_shop_id ?? "" },
        body: {
          start_date,
          end_date,
        },
      }
    ),
    enabled: !!system_shop_id,
  });

  const categories = useMemo(() => {
    // Compute categories based on data
    return trafficSource?.data?.map(
      (item) => item?.customer_visit_source ?? "Unknown"
    );
  }, [trafficSource]);

  const series = useMemo(() => {
    // Compute series based on data
    return trafficSource?.data?.map((item) => item?.count_order);
  }, [trafficSource]);

  return (
    <React.Fragment>
      <div className="order-last col-span-12 lg:col-span-6  card 2xl:col-span-3">
        <div className="card-body">
          <h6 className="mb-3 text-15">Traffic Source</h6>

          <DistributedColumns
            data={series ?? []}
            categories={categories ?? []}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrafficSource;
