import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import React from "react";

function SecretKey( ) {
  return (
    <React.Fragment>
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="Secret key" pageTitle="Secret key" />

        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-3">
          <div className="card">
            <div className="card-body">
              <form
                action="/"
                onSubmit={(event: any) => event.preventDefault()}
              >
                <div className="mb-3">
                  <label
                    htmlFor="inputLabel"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Shopify
                  </label>
                  <input
                    type="password"
                    id="inputTitle"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    required
                    placeholder="Enter key"
                  />
                </div>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form
                action="/"
                onSubmit={(event: any) => event.preventDefault()}
              >
                <div className="mb-3">
                  <label
                    htmlFor="inputLabel"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    BigCommerce
                  </label>
                  <input
                    type="password"
                    id="inputTitle"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    required
                    placeholder="Enter key"
                  />
                </div>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form
                action="/"
                onSubmit={(event: any) => event.preventDefault()}
              >
                <div className="mb-3">
                  <label
                    htmlFor="inputLabel"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    WooCommerce
                  </label>
                  <input
                    type="password"
                    id="inputTitle"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    required
                    placeholder="Enter key"
                  />
                </div>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form
                action="/"
                onSubmit={(event: any) => event.preventDefault()}
              >
                <div className="mb-3">
                  <label
                    htmlFor="inputLabel"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Magento
                  </label>
                  <input
                    type="password"
                    id="inputTitle"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    required
                    placeholder="Enter key"
                  />
                </div>
                <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(SecretKey);
