import React, { useMemo } from "react";
import { InteractionChart } from "./Charts";
import { type ShopifyAnalyticCountCustomerByCreatedAtAggregate } from "api/client/types.gen";

type InteractionProps = {
  data?: ShopifyAnalyticCountCustomerByCreatedAtAggregate[];
};

const Interaction = ({ data }: InteractionProps) => {
  const orderChartData = useMemo(() => {
    if (!data) return { series: [], categories: [] };
    return {
      series: data?.map((order) => order.count),
      categories: data?.map((order) => order.created_at),
    };
  }, [data]);

  return (
    <React.Fragment>
      <div className="order-7 col-span-12 2xl:order-1 card 2xl:col-span-7">
        <div className="card-body">
          <div className="flex items-center gap-2">
            <h6 className="mb-3 text-15 grow">New Customers</h6>
          </div>
          <InteractionChart
            chartId="pagesInteraction"
            data-chart-colors='["bg-custom-500", "bg-purple-500"]'
            series={orderChartData.series}
            categories={orderChartData.categories}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Interaction;
