import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";

import {
  countShopifyCustomersV1ShopifyShopSystemShopIdSystemCustomerCountGetOptions,
  fetchLessSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductLessSellingPostOptions,
  fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions,
  fetchTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalRevenuePostOptions,
  getAverageOrderValueV1ShopifyShopSystemShopIdSystemAnalyticsOrderAverageOrderValuePostOptions,
  getCountCustomerByCreatedAtV1ShopifyShopSystemShopIdSystemAnalyticsCustomerCountCustomerByCreatedAtPostOptions,
  getOrderRefundsV1ShopifyShopSystemShopIdSystemAnalyticsOrderRefundsPostOptions,
  getRevenueCurrentPeriodVsPreviousPeriodV1ShopifyShopSystemShopIdSystemAnalyticsOrderRevenueCurrentPeriodVsPreviousPeriodPostOptions,
  getShopifyListV1ShopifyListGetInfiniteOptions,
  getTotalCustomerMadePurchaseV1ShopifyShopSystemShopIdSystemAnalyticsCustomerPurchasePostOptions,
  getTotalOrderByDeviceTypeV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByDeviceTypePostOptions,
} from "api/client/@tanstack/react-query.gen";
import { DEFAULT_PAGING } from "Common/constants/api";
import Select from "react-select";
import SalesRevenue from "../Ecommerce/SalesRevenue";
import TopSellingProducts from "../Ecommerce/TopSellingProducts";
import DailyVisit from "./DailyVisit";
import Interaction from "./Interaction";
import LocationBased from "./LocationBased";
import MonthlyCampaign from "./MonthlyCampaign";
import ProductsStatistics from "./ProductsStatistics";
import Reports from "./Reports";
import Satisfaction from "./Satisfaction";
import Subscription from "./Subscription";
import TopCountries from "./TopCountry";
import TrafficSource from "./TrafficSource";
import UserDevice from "./UserDevice";
import Widgets from "./Widgets";
import TopSellingProductsTable from "./TopSellingProductsTable";
import CustomerStatistic from "./CustomerStatistic";
import { differenceInMonths } from "date-fns";
import { formatDateTime } from "utils/date-time";
import LowestInventory from "./LowestInventory";

type AnalyticComponentsType = {
  id:
    | "widgets"
    | "locationBased"
    | "interaction"
    | "userDevice"
    | "satisfaction"
    | "dailyVisit"
    | "productsStatistics"
    | "reports"
    | "monthlyCampaign"
    | "subscription"
    | "trafficSource"
    | "topSellingProducts"
    | "lessSellingProducts"
    | "topCountries";
  title: string;
  checked: boolean;
};

// Create a mapping between component ids and their respective components
const COMPONENT_MAP = {
  widgets: Widgets,
  locationBased: LocationBased,
  interaction: Interaction,
  userDevice: UserDevice,
  satisfaction: Satisfaction,
  dailyVisit: DailyVisit,
  productsStatistics: ProductsStatistics,
  reports: Reports,
  monthlyCampaign: MonthlyCampaign,
  subscription: Subscription,
  trafficSource: TrafficSource,
  topSellingProducts: TopSellingProducts,
  lessSellingProducts: TopSellingProducts,
  topCountries: TopCountries,
};

const DEFAULT_LIST_COMPONENTS: AnalyticComponentsType[] = [
  { id: "widgets", title: "Widgets", checked: true },
  { id: "locationBased", title: "Location Based", checked: true },
  { id: "interaction", title: "Interaction", checked: true },
  { id: "userDevice", title: "User Device", checked: false },
  { id: "satisfaction", title: "Satisfaction", checked: false },
  { id: "dailyVisit", title: "Daily Visit", checked: false },
  { id: "productsStatistics", title: "Products Statistics", checked: true },
  { id: "reports", title: "Reports", checked: false },
  { id: "monthlyCampaign", title: "Monthly Campaign", checked: false },
  { id: "subscription", title: "Subscription", checked: false },
  { id: "trafficSource", title: "Traffic Source", checked: false },
  { id: "topSellingProducts", title: "Top Selling Products", checked: true },
  { id: "lessSellingProducts", title: "Less Selling Products", checked: true },
  { id: "topCountries", title: "Top Countries", checked: true },
];

const Analytics = () => {
  let currentDate = new Date();

  const [selectedStore, setSelectedStore] = useState<any>();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(currentDate.setMonth(currentDate.getMonth() - 1)),
    new Date(),
  ]);
  const [paging, setPaging] = useState(DEFAULT_PAGING);

  const [listComponent] = useState<AnalyticComponentsType[]>(
    DEFAULT_LIST_COMPONENTS
  );

  const { data: totalRevenue } = useQuery({
    ...fetchTotalPriceV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalRevenuePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: totalRefunds } = useQuery({
    ...getOrderRefundsV1ShopifyShopSystemShopIdSystemAnalyticsOrderRefundsPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: averages } = useQuery({
    ...getAverageOrderValueV1ShopifyShopSystemShopIdSystemAnalyticsOrderAverageOrderValuePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const currency = useMemo(() => {
    return averages?.data?.at(0)?.currency ?? "";
  }, [averages?.data?.at(0)?.currency]);

  const { data: customer } = useQuery({
    ...countShopifyCustomersV1ShopifyShopSystemShopIdSystemCustomerCountGetOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: customersPurchase } = useQuery({
    ...getTotalCustomerMadePurchaseV1ShopifyShopSystemShopIdSystemAnalyticsCustomerPurchasePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: topSellingProducts } = useQuery({
    ...fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
          limit: 10,
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: lessSellingProducts } = useQuery({
    ...fetchLessSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductLessSellingPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
          limit: 10,
        },
      }
    ),
    enabled: !!selectedStore?.value && !!dateRange,
  });

  const { data: stores, fetchNextPage } = useInfiniteQuery({
    ...getShopifyListV1ShopifyListGetInfiniteOptions({
      query: {
        limit: paging.limit,
        page: paging.page,
      },
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data && lastPage?.data?.length < paging.limit) {
        return undefined;
      }

      return paging.page + 1;
    },
    initialPageParam: 1,
  });

  const { data: newCustomers } = useQuery({
    ...getCountCustomerByCreatedAtV1ShopifyShopSystemShopIdSystemAnalyticsCustomerCountCustomerByCreatedAtPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
    enabled: !!selectedStore?.value,
  });

  const { data: revenuePeriod } = useQuery({
    ...getRevenueCurrentPeriodVsPreviousPeriodV1ShopifyShopSystemShopIdSystemAnalyticsOrderRevenueCurrentPeriodVsPreviousPeriodPostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
  });

  const { data: deviceCategory } = useQuery({
    ...getTotalOrderByDeviceTypeV1ShopifyShopSystemShopIdSystemAnalyticsOrderTotalOrderByDeviceTypePostOptions(
      {
        path: { system_shop_id: selectedStore?.value ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
        },
      }
    ),
  });
  const deviceCategoryData = useMemo(() => {
    const sortedCategories = deviceCategory?.data?.sort((a, b) => {
      return ("" + a?.device_type).localeCompare(b?.device_type + "");
    });

    const series = sortedCategories?.map((item) => +item?.count_order) ?? [];
    const labels =
      sortedCategories?.map((item) => item?.device_type ?? " Unknown") ?? [];

    return { series: series, labels: labels };
  }, [deviceCategory]);

  const storeOptions = useMemo(() => {
    return stores?.pages
      ?.map(({ data }) =>
        data?.map((item) => ({
          value: item._id,
          label: item.shop_name,
        }))
      )
      .flat();
  }, [stores]);

  useEffect(() => {
    if (!selectedStore && !!storeOptions && storeOptions.length) {
      setSelectedStore(storeOptions?.[0]);
    }
  }, [selectedStore, storeOptions]);

  const handleLoadMore = () => {
    fetchNextPage();
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Analytics" pageTitle="Dashboards" />
      <div className="p-2 bg-white mb-5 flex gap-2 *:w-1/2 dark:bg-zink-600">
        <Select
          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800s placeholder:dark:text-white"
          id="choices-single-no-sorting"
          name="choices-single-no-sorting"
          placeholder="Select Store"
          data-choices
          data-choices-sorting-false
          options={storeOptions}
          value={selectedStore}
          onChange={(value: any) => {
            setSelectedStore(value);
          }}
          onMenuScrollToBottom={handleLoadMore}
        />
        <Flatpickr
          value={dateRange}
          options={{ mode: "range", dateFormat: "d M, Y" }}
          placeholder="Select Date"
          onChange={(date) => {
            if (date.length === 2) {
              const [startDate, endDate] = date;

              // Calculate the difference in months
              const monthsDifference = differenceInMonths(endDate, startDate);

              // Check if the range exceeds 3 months
              if (monthsDifference > 3) {
                alert("Please select a range of no more than 3 months.");
                return;
              }

              setDateRange(date);
            }
          }}
          className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
        />
      </div>
      <div className="grid grid-cols-12 gap-x-5">
        <Widgets
          total={{
            revenue: {
              total: totalRevenue?.data?.at(0),
              currentPeriod: revenuePeriod?.data?.at(0),
              previousPeriod: revenuePeriod?.data?.at(1),
            },
            averageOrder: averages?.data?.at(0),
            customer: {
              total: customer?.data ?? 0,
              madePurchase: customersPurchase?.data?.length ?? 0,
            },
            refunds: totalRefunds?.data?.at(0),
          }}
        />
        <SalesRevenue
          system_shop_id={selectedStore?.value}
          className="order-2 col-span-12 card 2xl:col-span-12"
          currency={currency}
        />
        <LocationBased
          system_shop_id={selectedStore?.value}
          start_date={formatDateTime(dateRange?.[0]?.toString())}
          end_date={formatDateTime(dateRange?.[1]?.toString())}
        />
        <Interaction data={newCustomers?.data ?? []} />

        <TopSellingProductsTable
          dateRange={dateRange}
          selectedStoreId={selectedStore?.value}
          currency={currency}
        />

        <TopSellingProducts
          name="Top Selling Product"
          products={topSellingProducts?.data ?? []}
        />
        <TopSellingProducts
          name="Less Selling Product"
          products={lessSellingProducts?.data ?? []}
        />

        <TopCountries
          system_shop_id={selectedStore?.value}
          start_date={formatDateTime(dateRange?.[0]?.toString())}
          end_date={formatDateTime(dateRange?.[1]?.toString())}
        />
        <CustomerStatistic
          system_shop_id={selectedStore?.value}
          start_date={formatDateTime(dateRange?.[0]?.toString())}
          end_date={formatDateTime(dateRange?.[1]?.toString())}
          className="order-2 col-span-12 card 2xl:col-span-12"
        />
        <TrafficSource
          system_shop_id={selectedStore?.value}
          start_date={formatDateTime(dateRange?.[0]?.toString())}
          end_date={formatDateTime(dateRange?.[1]?.toString())}
        />
        <Subscription
          className="order-last"
          title="Device Category"
          labels={deviceCategoryData.labels}
          series={deviceCategoryData.series}
        />
        <LowestInventory system_shop_id={selectedStore?.value} />
      </div>
    </React.Fragment>
  );
};

export default Analytics;
