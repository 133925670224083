import React, { useMemo } from "react";
import { LocationChart } from "./Charts";
import { useQuery } from "@tanstack/react-query";
import { getCountOrderGroupByDayV1ShopifyShopSystemShopIdSystemAnalyticsOrderCountOrderGroupByDayPostOptions } from "api/client/@tanstack/react-query.gen";

type LocationBasedProps = {
  system_shop_id?: string;
  start_date?: string;
  end_date?: string;
};

const LocationBased = ({
  system_shop_id,
  end_date,
  start_date,
}: LocationBasedProps) => {
  const { data: orders } = useQuery({
    ...getCountOrderGroupByDayV1ShopifyShopSystemShopIdSystemAnalyticsOrderCountOrderGroupByDayPostOptions(
      {
        path: { system_shop_id: system_shop_id ?? "" },
        body: {
          start_date,
          end_date,
        },
      }
    ),
    enabled: !!system_shop_id,
  });

  const orderChartData = useMemo(() => {
    if (!orders) return { series: [], categories: [] };
    return {
      series: orders?.data?.map((order) => order.count),
      categories: orders?.data?.map((order) => order.date),
    };
  }, [orders]);

  return (
    <React.Fragment>
      <div className="order-6 col-span-12 2xl:order-1 card 2xl:col-span-5">
        <div className="card-body">
          <div className="flex items-center gap-2">
            <h6 className="mb-3 text-15 grow">Orders selled per day</h6>
          </div>

          <LocationChart
            chartId="responseTimes"
            series={orderChartData.series}
            categories={orderChartData.categories}
            data-chart-colors='["bg-red-500"]'
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocationBased;
