import React, { useMemo } from "react";

// Image
import { Link, useLocation } from "react-router-dom";
import { useShopifyCallbackQuery } from "api/query/shopify/useShopifyCallbackQuery";

const getSearchParamsAsObject = (
  searchParams: string
): Record<string, string> => {
  const params = new URLSearchParams(searchParams);
  const paramsObject: Record<string, string> = {};

  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};

const ConnectShopify = () => {
  document.title = "ConnectShopify";

  const location = useLocation();
  const params = useMemo(
    () => getSearchParamsAsObject(location.search),
    [location.search]
  );

  const { isLoading, isSuccess, isError } = useShopifyCallbackQuery(params);

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      "flex",
      "items-center",
      "justify-center",
      "min-h-screen",
      "py-16",
      "bg-cover",
      "bg-auth-pattern",
      "dark:bg-auth-pattern-dark",
      "font-public"
    );

    return () => {
      bodyElement.classList.remove(
        "flex",
        "items-center",
        "justify-center",
        "min-h-screen",
        "py-16",
        "bg-cover",
        "bg-auth-pattern",
        "dark:bg-auth-pattern-dark",
        "font-public"
      );
    };
  }, []);

  const connectStatus: any = (
    <div id="countDownText">
      <div className="mt-8 text-center">
        <h4 className="mb-2 text-purple-500 dark:text-purple-500">
          Store Connecting ...
        </h4>
        <p className="text-slate-500 dark:text-zink-200">
          We'll be here in a brief moment.
        </p>
      </div>
    </div>
  );

  const successBlock: any = (
    <div id="countDownText">
      <div className="text-center">
        <i className="text-4xl text-green-500 ri-verified-badge-line"></i>
        <div className="mt-5 mb-4">
          <h4 className="mb-2">We've connected your Shopify store</h4>
        </div>
        <Link
          to="/dashboard"
          className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        >
          Back to Dashboard
        </Link>
      </div>
    </div>
  );

  const errorBlock: any = (
    <div id="countDownText">
      <div className="text-center">
        <i className="text-4xl text-red-500 ri-error-warning-fil"></i>
        <div className="mt-5 mb-4">
          <h4 className="mb-2">
            Sorry, We can not connect to your store! Please Try again!
          </h4>
        </div>
        <Link
          to="/dashboard"
          className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        >
          Back to Dashboard
        </Link>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="mb-0 border-none shadow-none lg:w-[500px] card bg-white/70 dark:bg-zink-500/70">
        <div className="!px-10 !py-12 card-body">
          {isLoading && connectStatus}
          {isSuccess && successBlock}
          {isError && errorBlock}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConnectShopify;
