import { profileFailed, profileSuccess } from "./reducer";
import { RootState } from "slices";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { UserResponseDTO } from "api/http";

export const editProfile =
  (
    user?: UserResponseDTO | null
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      if (user) {
        dispatch(profileSuccess(user));
      }
    } catch (error) {
      dispatch(profileFailed(error));
    }
  };
