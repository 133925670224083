/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_swagger_login_v1_users_swagger_login_post } from '../models/Body_swagger_login_v1_users_swagger_login_post';
import type { ForgotPasswordDTO } from '../models/ForgotPasswordDTO';
import type { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import type { ResponseModel_NoneType_ } from '../models/ResponseModel_NoneType_';
import type { ResponseModel_Token_ } from '../models/ResponseModel_Token_';
import type { ResponseModel_UserResponseDTO_ } from '../models/ResponseModel_UserResponseDTO_';
import type { Token } from '../models/Token';
import type { UserLoginDTO } from '../models/UserLoginDTO';
import type { UserRegisterDTO } from '../models/UserRegisterDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Register User
     * @param requestBody
     * @returns ResponseModel_UserResponseDTO_ Successful Response
     * @throws ApiError
     */
    public static registerUserV1UsersRegisterPost(
        requestBody: UserRegisterDTO,
    ): CancelablePromise<ResponseModel_UserResponseDTO_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Login For Access Token
     * @param requestBody
     * @returns ResponseModel_Token_ Successful Response
     * @throws ApiError
     */
    public static loginForAccessTokenV1UsersLoginPost(
        requestBody: UserLoginDTO,
    ): CancelablePromise<ResponseModel_Token_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Swagger Login
     * @param formData
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static swaggerLoginV1UsersSwaggerLoginPost(
        formData: Body_swagger_login_v1_users_swagger_login_post,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/swagger_login',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Current User
     * @returns ResponseModel_UserResponseDTO_ Successful Response
     * @throws ApiError
     */
    public static getCurrentUserV1UsersMeGet(): CancelablePromise<ResponseModel_UserResponseDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/me',
        });
    }
    /**
     * Forgot Password
     * @param requestBody
     * @returns ResponseModel_NoneType_ Successful Response
     * @throws ApiError
     */
    public static forgotPasswordV1UsersForgotPasswordPost(
        requestBody: ForgotPasswordDTO,
    ): CancelablePromise<ResponseModel_NoneType_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reset Password
     * @param requestBody
     * @returns ResponseModel_NoneType_ Successful Response
     * @throws ApiError
     */
    public static resetPasswordV1UsersResetPasswordPost(
        requestBody: ResetPasswordDTO,
    ): CancelablePromise<ResponseModel_NoneType_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
