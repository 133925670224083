import React, { useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { DEFAULT_PAGING } from "Common/constants/api";
// Icon
import {
  ChevronLeft,
  ChevronRight,
  MoreHorizontal,
  Search,
  Unplug,
} from "lucide-react";

// react-redux

import { ToastContainer } from "react-toastify";
import Select from "react-select";
import TableContainer from "Common/TableContainer";
import {
  fetchProductsV1ShopifyShopSystemShopIdFetchProductsGetOptions,
  getShopifyListV1ShopifyListGetInfiniteOptions,
} from "api/client/@tanstack/react-query.gen";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ShopifyProductResponse } from "api/http";
import { ColumnDef } from "@tanstack/react-table";

const ShopifyProducts = () => {
  const [selectedStore, setSelectedStore] = useState<any>();
  const [paging, setPaging] = useState(DEFAULT_PAGING);
  const [pagingProduct, setPagingProduct] = useState(DEFAULT_PAGING);

  const { data: stores, fetchNextPage } = useInfiniteQuery({
    ...getShopifyListV1ShopifyListGetInfiniteOptions({
      query: {
        limit: paging.limit,
        page: paging.page,
      },
    }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data && lastPage?.data?.length < paging.limit) {
        return undefined;
      }

      return paging.page + 1;
    },
    initialPageParam: 1,
  });

  const {
    data: products,
    refetch,
    isLoading,
  } = useQuery({
    ...fetchProductsV1ShopifyShopSystemShopIdFetchProductsGetOptions({
      path: {
        system_shop_id: selectedStore?.value,
      },
      query: {
        ...pagingProduct,
      },
    }),
  });

  const storeOptions = useMemo(() => {
    return stores?.pages
      ?.map(({ data }) =>
        data?.map((item) => ({
          value: item._id,
          label: item.shop_name,
        }))
      )
      .flat();
  }, [stores]);

  useEffect(() => {
    if (!selectedStore && !!storeOptions && storeOptions.length) {
      setSelectedStore(storeOptions?.[0]);
    }
  }, [selectedStore, storeOptions]);

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const columns = useMemo<ColumnDef<ShopifyProductResponse, any>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "title",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Image",
        accessorKey: "image",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <img
            src={cell.getValue()?.src}
            className="w-10 h-10"
            alt={cell.getValue()?.id}
          />
        ),
      },
      {
        header: "Vendor",
        accessorKey: "vendor",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Link to={cell.getValue()} className="flex items-center gap-2">
            <h6 className="product_name">{cell.getValue()}</h6>
          </Link>
        ),
      },
      {
        header: "Body HTML",
        accessorKey: "body_html",
        enableColumnFilter: false,

        cell: (cell: any) => (
          <span className="category truncate max-w-40 px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">
            {cell.getValue()}
          </span>
        ),
      },
      {
        header: "Product Type",
        accessorKey: "product_type",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
      {
        header: "Published At",
        accessorKey: "published_at",
        enableColumnFilter: false,
      },
      {
        header: "Template Suffix",
        accessorKey: "template_suffix",
        enableColumnFilter: false,
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        enableColumnFilter: false,
      },
      {
        header: "Price",
        accessorKey: "variants",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.price,
      },
      {
        header: "Weight",
        accessorKey: "weight",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.weight,
      },
      {
        header: "Sku",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.sku,
      },
      {
        header: "Position",
        accessorKey: "position",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.position,
      },
      {
        header: "Fulfillment Service",
        accessorKey: "fulfillment_service",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.fulfillment_service,
      },
      {
        header: "Barcode",
        accessorKey: "barcode",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => cell.getValue()?.[0]?.barcode,
      },
    ],
    []
  );

  const previousPage = () => {
    setPagingProduct((prev) => ({
      ...prev,
      page: prev.page - 1,
    }));
  };

  const nextPage = () => {
    setPagingProduct((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Product" pageTitle="Shopify" />

      <ToastContainer closeButton={false} limit={1} />
      <div className="card flex flex-1 flex-col" id="productListTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  disabled
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>
            <div className="xl:col-span-3">
              <div className="relative">
                <Select
                  className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="choices-single-no-sorting"
                  name="choices-single-no-sorting"
                  placeholder="Select Store"
                  data-choices
                  data-choices-sorting-false
                  options={storeOptions}
                  value={selectedStore}
                  onChange={(value: any) => {
                    setSelectedStore(value);
                    refetch();
                  }}
                  onMenuScrollToBottom={handleLoadMore}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="!pt-1 card-body h-full">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <div className="loader">Loading...</div>
            </div>
          ) : products?.data && products?.data?.length > 0 ? (
            <div>
              <TableContainer
                isLoading={isLoading}
                isPagination={false}
                columns={columns || []}
                data={products?.data || []}
                customPageSize={products?.data?.length}
                divclassName="overflow-x-auto min-h-[300px]"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
              />
              <div className="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row">
                <div className="mb-4 grow md:mb-0">
                  <div className="text-slate-500 dark:text-zink-200">
                    Showing
                    <b> {products?.data?.length}</b> of
                    <b> {products?.data?.length}</b> Results
                  </div>
                </div>
                <ul className="flex flex-wrap items-center gap-2 shrink-0">
                  <li>
                    <Link
                      to="#!"
                      className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto ${
                        paging.page === 1 && "disabled"
                      }`}
                      onClick={previousPage}
                    >
                      <ChevronLeft className="size-4 mr-1 rtl:rotate-180"></ChevronLeft>{" "}
                      Prev
                    </Link>
                  </li>
                  {/* {getPageOptions().map((item: any, key: number) => (
                <React.Fragment key={key}>
                  <li>
                    <Link to="#" className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 size-8 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-white dark:[&.active]:text-white [&.active]:bg-custom-500 dark:[&.active]:bg-custom-500 [&.active]:border-custom-500 dark:[&.active]:border-custom-500 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto ${getState().pagination.pageIndex === item && "active"}`} onClick={() => setPageIndex(item)}>{item + 1}</Link>
                  </li>
                </React.Fragment>
              ))} */}
                  <li>
                    <Link
                      to="#!"
                      className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-auto`}
                      onClick={() => nextPage()}
                    >
                      Next{" "}
                      <ChevronRight className="size-4 ml-1 rtl:rotate-180"></ChevronRight>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto mb-3 text-sky-500 fill-sky-100 dark:fill-sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShopifyProducts;
