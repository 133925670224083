import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

const DistributedColumns = ({ chartId, data = [], categories = [] }: any) => {
  const chartColors = useChartColors(chartId);

  //Distributed Columns
  const [seriesData, setSeriesData] = useState<any>([
    {
      data: data,
    },
  ]);
  const [options, setOptions] = useState<any>({
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart: any, w: any, e: any) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: chartColors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
        style: {
          colors: chartColors,
          fontSize: "12px",
        },
      },
    },
  });

  useEffect(() => {
    setSeriesData([
      {
        data: data,
      },
    ]);
  }, [data]);

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: categories,
      },
    });
  }, [categories]);

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={seriesData || []}
        data-chart-colors='["bg-custom-500", "bg-yellow-500", "bg-green-500", "bg-orange-500", "bg-sky-500", "bg-purple-500", "bg-red-500", "bg-emerald-500"]'
        id={chartId}
        className="apex-charts"
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export { DistributedColumns };
