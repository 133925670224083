import React, { useMemo, useState } from "react";

import "flatpickr/dist/flatpickr.css";
import { useQuery } from "@tanstack/react-query";
import { getNewCustomerAndRepeatCustomerV1ShopifyShopSystemShopIdSystemAnalyticsOrderNewCustomerAndRepeatCustomerPostOptions } from "api/client/@tanstack/react-query.gen";
import { format, differenceInDays, addDays } from "date-fns";
import { cls } from "utils/theme";
import { ReportChart } from "./Charts";

type CustomerStatisticProps = {
  system_shop_id?: string;
  start_date?: string;
  end_date?: string;
  className?: string;
};

const CustomerStatistic = (props: CustomerStatisticProps) => {
  const { className } = props;

  const { data: customerStatistic } = useQuery({
    ...getNewCustomerAndRepeatCustomerV1ShopifyShopSystemShopIdSystemAnalyticsOrderNewCustomerAndRepeatCustomerPostOptions(
      {
        path: { system_shop_id: props.system_shop_id ?? "" },
        body: {
          start_date: props?.start_date,
          end_date: props?.end_date,
        },
      }
    ),
    enabled: !!props.system_shop_id,
  });

  const arrayOfDays = useMemo(() => {
    const startDate = props.start_date;
    const endDate = props.end_date;
    if (!startDate || !endDate) return [];

    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalDays = differenceInDays(end, start);

    // Generate the list of day short names
    const days = [];
    for (let i = 0; i <= totalDays; i++) {
      const dayDate = addDays(start, i);
      days.push(format(dayDate, "dd/MM/yyyy")); // 'EEE' -> Mon, Tue, Wed
    }

    return days;
  }, [props.start_date, props.end_date]);

  return (
    <React.Fragment>
      <div className={cls("col-span-12 card 2xl:col-span-8 ", className)}>
        <div className="card-body">
          <div className="flex flex-col gap-4 mb-4 md:mb-3 md:items-center md:flex-row">
            <h6 className="grow text-15">New Customer vs Repeat Customer</h6>
          </div>

          <ReportChart
            chartId="CustomerStatisticOverview"
            data={customerStatistic?.data}
            categories={arrayOfDays ?? []}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerStatistic;
