import React, { useMemo } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import { DEFAULT_PAGING } from "Common/constants/api";
// Icon
import { MoreHorizontal, Search, Plus, Unplug } from "lucide-react";

import TableContainer from "Common/TableContainer";

// react-redux

import { ToastContainer } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  disconnectShopifyShopV1ShopifyShopSystemShopIdDisconnectPostMutation,
  getShopifyListV1ShopifyListGetOptions,
} from "api/client/@tanstack/react-query.gen";

const ShopifyStore = () => {
  const { data: stores, refetch } = useQuery({
    ...getShopifyListV1ShopifyListGetOptions({
      query: {
        ...DEFAULT_PAGING,
      },
    }),
  });
  const disconnectMutation = useMutation({
    ...disconnectShopifyShopV1ShopifyShopSystemShopIdDisconnectPostMutation(),
  });

  const handleDisconnect = async (shopId: any) => {
    disconnectMutation.mutate({
      path: {
        system_shop_id: shopId,
      },
    });
    refetch();
  };

  const columns = useMemo(
    () => [
      {
        header: "Shop ID",
        accessorKey: "shop_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Shop Name",
        accessorKey: "shop_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "URL",
        accessorKey: "url",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Link to={cell.getValue()} className="flex items-center gap-2">
            <h6 className="product_name">{cell.getValue()}</h6>
          </Link>
        ),
      },
      {
        header: "Currency",
        accessorKey: "currency_code",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="category px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">
            {cell.getValue()}
          </span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative dropdown">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="productAction1"
              data-bs-toggle="dropdown"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="productAction1"
            >
              <button onClick={() => handleDisconnect(cell.row.original?._id)}>
                <Unplug className="inline-block size-3 ltr:mr-1 rtl:ml-1 ml-1" />{" "}
                <span className="align-middle">Disconnect</span>
              </button>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Store" pageTitle="Shopify" />

      <ToastContainer closeButton={false} limit={1} />
      <div className="card flex flex-1 flex-col" id="productListTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-12">
            <div className="xl:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  disabled
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>
          </div>
        </div>
        <div className="!pt-1 card-body h-full">
          {stores?.data && stores?.data?.length > 0 ? (
            <TableContainer
              isPagination={true}
              columns={columns || []}
              data={stores?.data || []}
              customPageSize={stores?.data?.length}
              divclassName="overflow-x-auto min-h-[300px]"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center gap-4 px-4 mt-4 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto mb-3 text-sky-500 fill-sky-100 dark:fill-sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShopifyStore;
