import React from "react";
import { SubscriptionChart, SubscriptionChartProps } from "./Charts";
import { cls } from "utils/theme";
interface Props extends Omit<SubscriptionChartProps, "chartId"> {
  className?: string;
  title?: string;
}
const Subscription = ({
  className,
  labels,
  series,
  title = "Subscription Distribution",
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={cls(
          "order-last col-span-12 lg:col-span-6  card 2xl:col-span-3",
          className
        )}
      >
        <div className="card-body">
          <h6 className="mb-3 text-15">{title}</h6>
          <SubscriptionChart
            chartId="subscriptionDistribution"
            labels={labels}
            series={series}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscription;
