import React from "react";

//import images
import img02 from "assets/images/product/img-02.png";
import { ShoppingCart } from "lucide-react";
import { ShopifyAnalyticProductAggregate } from "api/client";

type TopSellingProductsProps = {
  name?: string;
  products?: ShopifyAnalyticProductAggregate[];
};

const TopSellingProducts = (props: TopSellingProductsProps) => {
  return (
    <React.Fragment>
      <div className="col-span-12 card lg:col-span-6 2xl:col-span-3 order-last">
        <div className="card-body">
          <div className="flex items-center mb-3">
            <h6 className="grow text-15">{props.name}</h6>
          </div>
          <ul className="flex flex-col gap-5">
            {props.products?.map((product) => {
              return (
                <li
                  className="flex items-center gap-3"
                  key={product.shopify_product_id}
                >
                  <div className="flex items-center justify-center size-10 rounded-md bg-slate-100 dark:bg-zink-600">
                    <img
                      src={
                        product?.image?.src ??
                        product?.images?.[0]?.src ??
                        img02
                      }
                      alt=""
                      className="h-6"
                    />
                  </div>
                  <div className="overflow-hidden grow">
                    <h6 className="truncate">{product?.name}</h6>
                    {/* <div className="text-yellow-500">
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-fill"></i>
                      <i className="ri-star-half-fill"></i>
                    </div> */}
                  </div>
                  <h6 className="shrink-0">
                    <ShoppingCart className="inline-block size-4 align-middle text-slate-500 dark:text-zink-200 ltr:mr-1 rtl:ml-1"></ShoppingCart>{" "}
                    {product?.total_quantity_sold}
                  </h6>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopSellingProducts;
