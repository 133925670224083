import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserResponseDTO } from "api/http";

export type ProfileState = {
  user?: UserResponseDTO;
  error: string;
  success: boolean;
};

const initialState: ProfileState = {
  error: "",
  success: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileSuccess(
      state: ProfileState,
      action: PayloadAction<UserResponseDTO>
    ) {
      state.user = action.payload;
      state.success = true;
    },
    profileFailed(state: ProfileState, action: PayloadAction<string | any>) {
      state.error = action.payload;
      state.success = false;
    },
  },
});

export const { profileSuccess, profileFailed } = profileSlice.actions;
export default profileSlice.reducer;
