import React from "react";
import "./assets/scss/themes.scss";
import RouteIndex from "Routes/Index";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { client } from "api/client";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

client.setConfig({
  baseURL: process.env.REACT_APP_APIKEY ?? "",
});

client.instance.interceptors.request.use((config) => {
  const authUser: any = localStorage.getItem("authUser");
  const token = JSON.parse(authUser)
    ? JSON.parse(authUser).access_token
    : undefined;

  config.headers.set("Authorization", "Bearer " + token);
  return config;
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouteIndex />{" "}
    </QueryClientProvider>
  );
}

export default App;
