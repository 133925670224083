import { ThunkAction } from "redux-thunk";
import { RootState } from "slices";
import { Action, Dispatch } from "redux";
import { registerFailed, registerSuccess, resetRegister } from "./reducer";
import { UsersService } from "api/http";

interface User {
  email: string;
  username: string;
  password: string;
}

export const registerUser =
  (user: User): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      const response = await UsersService.registerUserV1UsersRegisterPost({
        ...user,
        full_name: user.username,
      });

      if (response?.code === 200) {
        dispatch(registerSuccess(response?.data?.email ?? ""));
      }
    } catch (error) {
      dispatch(registerFailed(error));
    }
  };

export const resetRegisterFlag = () => {
  try {
    const response = resetRegister(false);
    return response;
  } catch (error) {
    return error;
  }
};
