import { useQuery } from "@tanstack/react-query";
import { ShopifyService } from "api/http";

const useShopifyCallbackQuery = (variables: Record<string, any>) => {
  return useQuery({
    queryKey: useShopifyCallbackQueryKey,
    queryFn: () =>
      ShopifyService.checkShopifyAuthV1ShopifyCallbackPost(variables),
    enabled: !!variables,
  });
};

const useShopifyCallbackQueryKey = ["shopify/callback"];

export { useShopifyCallbackQuery, useShopifyCallbackQueryKey };
