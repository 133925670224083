import { useQueryMe } from "api/query/user/useQueryMe";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { editProfile } from "slices/thunk";

interface AuthProtectedProps {
  children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const { data, isError, isSuccess } = useQueryMe();
  const dispatch = useDispatch<any>();

  if (!localStorage.getItem("authUser")) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (isError) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (isSuccess && data?.code === 200) {
    dispatch(editProfile(data?.data));
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
