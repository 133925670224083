import { useQuery } from "@tanstack/react-query";
import { UsersService } from "api/http";

const useQueryMe = () => {
  return useQuery({
    queryKey: useQueryMeKey,
    queryFn: () => UsersService.getCurrentUserV1UsersMeGet(),
  });
};

const useQueryMeKey = ["user"];

export { useQueryMe, useQueryMeKey };
