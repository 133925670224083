import { useQuery } from "@tanstack/react-query";
import { fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions } from "api/client/@tanstack/react-query.gen";
import React, { useState } from "react";
import ProductsStatistics from "./ProductsStatistics";
import { formatDateTime } from "utils/date-time";

type Props = {
  selectedStoreId: string;
  dateRange: Date[];
  currency?: string;
};

function TopSellingProductsTable({
  selectedStoreId,
  dateRange,
  currency,
}: Props) {
  const [keyword, setKeyword] = useState("");
  const { data: topSellingProducts } = useQuery({
    ...fetchTopSellingProductsV1ShopifyShopSystemShopIdSystemAnalyticsProductTopSellingPostOptions(
      {
        path: { system_shop_id: selectedStoreId ?? "" },
        body: {
          start_date: formatDateTime(dateRange?.[0]?.toString()),
          end_date: formatDateTime(dateRange?.[1]?.toString()),
          limit: 10,
        },
      }
    ),
    enabled: !!selectedStoreId && !!dateRange,
  });
  return (
    <ProductsStatistics
      data={topSellingProducts?.data ?? []}
      onSearch={(e) => setKeyword(e.target.value)}
      currency={currency}
    />
  );
}

export default TopSellingProductsTable;
