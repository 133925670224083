import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";

const StackedBars100 = ({ chartId, series, categories }: any) => {
  const chartColors = useChartColors(chartId);

  var options: any = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories ?? [],
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + " products";
        },
      },
    },
    colors: chartColors,
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series || []}
        data-chart-colors='["bg-custom-500", "bg-green-500", "bg-orange-500", "bg-yellow-500", "bg-purple-500", "bg-red-500"]'
        id={chartId}
        className="apex-charts"
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export { StackedBars100 };
