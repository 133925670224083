/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResponseModel } from '../models/ResponseModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ShopifyService {
    /**
     * Shopify Auth
     * @param shop
     * @returns ResponseModel Successful Response
     * @throws ApiError
     */
    public static shopifyAuthV1ShopifyAuthGet(
        shop: string,
    ): CancelablePromise<ResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/shopify/auth',
            query: {
                'shop': shop,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Shopify Auth
     * @param requestBody
     * @returns ResponseModel Successful Response
     * @throws ApiError
     */
    public static checkShopifyAuthV1ShopifyCallbackPost(
        requestBody: Record<string, any>,
    ): CancelablePromise<ResponseModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/shopify/callback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
